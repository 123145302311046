import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";
import GradientLine from "@/design-system/Utility/GradientLine";

import LinkButton from "@/design-system-v2/Button/LinkButton";
import FlexContainer from "@/design-system-v2/FlexContainer";

import Typography from "@/design-system-v3/Typography";

import Badge from "@/design-system-supernova/Badge";

import { theme } from "@/theme";

interface styledCourseCardProps {
  hover: boolean;
  spanCol: number;
  newHeader: boolean;
}

const CardCont = styled.div<styledCourseCardProps>`
  grid-column: span ${({ spanCol }) => spanCol};
  border-radius: 12px;
  border: 1px solid
    ${({ newHeader }) => (newHeader ? theme.colors.grayCool[100] : theme.colors.gray[200])};
  background: ${({ newHeader }) =>
    newHeader ? theme.colors.grayCool[50] : theme.colors.gray[100]};
  gap: ${theme.spacing(2)};
  min-height: 212px;
  padding: ${theme.spacing(3)} ${theme.spacing(4)};
  position: relative;
  overflow: hidden;
  &:hover {
    transform: scale(1.02);
    box-shadow: "0px 0px 2.04px 0px rgba(52, 64, 84, 0.18), 0px 0px 3.06px 0px rgba(52, 64, 84, 0.12)";
  }
`;

interface HeaderNewCourseCardProps {
  spanCol: number;
  pathname: string;
  imgSrc: string;
  heading: string;
  description: string;
  nextCohortDate: string;
  programDuration: string;
  badge?: any;
  newHeader?: boolean;
}

const HeaderNewCourseCard = ({
  spanCol,
  pathname,
  imgSrc,
  badge,
  heading,
  description,
  nextCohortDate,
  programDuration,
  newHeader,
}: HeaderNewCourseCardProps) => {
  return (
    <CardCont hover={true} spanCol={4} newHeader={!!newHeader}>
      <Link
        className="link__"
        href={{ pathname: pathname }}
        style={{ minHeight: "184px", display: "flex", flex: 1, flexDirection: "column" }}
      >
        <div style={{ position: "absolute", right: "-16px", top: "-16px" }}>{badge}</div>
        <FlexContainer gap={theme.spacing(2)}>
          <Badge
            size="sm"
            color="gray"
            theme="light"
            label={programDuration.split("Months")[0] + " Months"}
          ></Badge>
          <Badge size="sm" color="gray" theme="light" label={"Live online"}></Badge>
        </FlexContainer>
        <FlexContainer
          flexDirection={"column"}
          mt={theme.spacing(2)}
          gap={theme.spacing(4)}
          flex={1}
        >
          <Typography
            varient="heading6"
            color={theme.colors.primary[900]}
            mb={theme.spacing(2)}
            weightVarient="semibold"
          >
            {heading}
          </Typography>
          <FlexContainer gap={theme.spacing(2)} flexDirection={"row"}>
            <GradientLine />
            <Typography
              varient="bodyxs"
              color={theme.colors.gray[600]}
              mb={theme.spacing(2)}
              weightVarient="regular"
              pt={theme.spacing(1)}
            >
              Next Cohort
            </Typography>{" "}
            <Typography
              varient="bodyxs"
              color={theme.colors.gray[700]}
              mb={theme.spacing(2)}
              weightVarient="semibold"
              pt={theme.spacing(1)}
            >
              {nextCohortDate}
            </Typography>
          </FlexContainer>
          <FlexContainer flex={1} alignItems={"flex-end"}>
            <LinkButton
              style={{ padding: 0 }}
              type="button"
              color="default"
              size="sm"
              label={"Know More"}
              suffixIcon={
                <Icon
                  varient={"line"}
                  size={16}
                  type={"arrow-narrow-right"}
                  color={theme.colors.purple[800]}
                />
              }
            />
          </FlexContainer>
        </FlexContainer>
      </Link>
    </CardCont>
  );
};

export default HeaderNewCourseCard;
