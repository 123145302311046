import { theme } from "@/theme";

export const BadgeStyles = {
  dark: {
    green: {
      textColor: theme.colors.success[100],
      bgColor: theme.colors.success[500],
    },
    moss: {
      textColor: theme.colors.moss[100],
      bgColor: theme.colors.moss[500],
    },
    red: {
      textColor: theme.colors.error[100],
      bgColor: theme.colors.error[500],
    },
    orange: {
      textColor: theme.colors.orange[100],
      bgColor: theme.colors.orange[500],
    },
    yellow: {
      textColor: theme.colors.warning[100],
      bgColor: theme.colors.warning[500],
    },
    gray: {
      textColor: theme.colors.grayCool[100],
      bgColor: theme.colors.grayCool[400],
    },
    black: {
      textColor: theme.colors.gray[100],
      bgColor: theme.colors.gray[800],
    },
    blue: {
      textColor: theme.colors.blueDark[100],
      bgColor: theme.colors.blueDark[500],
    },
    indigo: {
      textColor: theme.colors.indigo[100],
      bgColor: theme.colors.indigo[500],
    },
    primary: {
      textColor: theme.colors.primary[100],
      bgColor: theme.colors.primary[500],
    },
    purple: {
      textColor: theme.colors.purple[100],
      bgColor: theme.colors.purple[500],
    },
    transparent: {
      textColor: theme.colors.gray[100],
      bgColor: "rgba(0, 0, 0, 0.2)",
    },
  },
  light: {
    green: {
      textColor: theme.colors.success[700],
      bgColor: theme.colors.success[100],
    },
    moss: {
      textColor: theme.colors.moss[700],
      bgColor: theme.colors.moss[100],
    },
    red: {
      textColor: theme.colors.error[700],
      bgColor: theme.colors.error[100],
    },
    orange: {
      textColor: theme.colors.orange[700],
      bgColor: theme.colors.orange[100],
    },
    yellow: {
      textColor: theme.colors.warning[700],
      bgColor: theme.colors.warning[100],
    },
    gray: {
      textColor: theme.colors.grayCool[800],
      bgColor: theme.colors.grayCool[200],
    },
    black: {
      textColor: theme.colors.gray[700],
      bgColor: theme.colors.grayCool[50],
    },
    blue: {
      textColor: theme.colors.blueDark[700],
      bgColor: theme.colors.blueDark[100],
    },
    indigo: {
      textColor: theme.colors.indigo[700],
      bgColor: theme.colors.indigo[100],
    },
    primary: {
      textColor: theme.colors.primary[700],
      bgColor: theme.colors.primary[100],
    },
    purple: {
      textColor: theme.colors.purple[700],
      bgColor: theme.colors.purple[100],
    },
    transparent: {
      textColor: theme.colors.gray[800],
      bgColor: "rgba(255, 255, 255, 0.20)",
    },
  },
  outline: {
    green: {
      borderColor: theme.colors.success[500],
      textColor: theme.colors.success[700],
    },
    moss: {
      borderColor: theme.colors.moss[500],
      textColor: theme.colors.moss[700],
    },
    red: {
      borderColor: theme.colors.error[500],
      textColor: theme.colors.error[700],
    },
    orange: {
      borderColor: theme.colors.orange[500],
      textColor: theme.colors.orange[700],
    },
    yellow: {
      borderColor: theme.colors.warning[500],
      textColor: theme.colors.warning[700],
    },
    gray: {
      borderColor: theme.colors.grayCool[500],
      textColor: theme.colors.grayCool[800],
    },
    black: {
      borderColor: theme.colors.base.white,
      textColor: theme.colors.gray[700],
    },
    blue: {
      borderColor: theme.colors.blueDark[500],
      textColor: theme.colors.blueDark[700],
    },
    indigo: {
      borderColor: theme.colors.indigo[500],
      textColor: theme.colors.indigo[700],
    },
    primary: {
      borderColor: theme.colors.primary[500],
      textColor: theme.colors.primary[700],
    },
    purple: {
      borderColor: theme.colors.purple[500],
      textColor: theme.colors.purple[700],
    },
    transparent: {
      borderColor: "rgba(0, 0, 0, 0.20)",
      textColor: theme.colors.gray[800],
    },
  },
  whiteBg: {
    green: {
      textColor: theme.colors.success[500],
    },
    moss: {
      textColor: theme.colors.moss[700],
    },
    red: {
      textColor: theme.colors.error[600],
    },
    orange: {
      textColor: theme.colors.orange[500],
    },
    yellow: {
      textColor: theme.colors.warning[500],
    },
    gray: {
      textColor: theme.colors.grayCool[800],
    },
    black: {
      textColor: theme.colors.gray[500],
    },
    blue: {
      textColor: theme.colors.blueDark[500],
    },
    indigo: {
      textColor: theme.colors.indigo[600],
    },
    primary: {
      textColor: theme.colors.primary[500],
    },
    purple: {
      textColor: theme.colors.purple[500],
    },
  },
};

export const SizeStyles = {
  sm: {
    fontStyles: {
      fontSize: "10px",
      lineHeight: "12px",
    },
    padding: "4px 8px",
    iconSize: 12,
    dotSize: "8px",
  },
  md: {
    fontStyles: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    padding: "4px 12px",
    iconSize: 12,
    dotSize: "8px",
  },
  lg: {
    fontStyles: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    padding: "4px 12px",
    iconSize: 16,
    dotSize: "10px",
  },
};
