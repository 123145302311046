import Link from "next/link";
import ExportedImage from "next-image-export-optimizer";
import styled from "styled-components";

import Box from "@/design-system/Box";
import GradientLine from "@/design-system/Utility/GradientLine";

import Typography from "@/design-system-v3/Typography";

import { theme } from "@/theme";

interface styledCourseCardProps {
  hover: boolean;
  spanCol: number;
}

const CardCont = styled.div<styledCourseCardProps>`
  grid-column: span ${({ spanCol }) => spanCol};
  transition: 0.3s;
  border-radius: 4px;
  .know-more {
    opacity: 0;
    transition: 0.3s;
  }

  .imgbox {
    transition: 0.3s;
    border-radius: 4px;
  }

  &:hover {
    background-color: ${(props) => (props.hover ? "white" : "")};
  }

  &:hover .imgbox {
    height: ${(props) => (props.hover ? "120px" : "")};
  }

  &:hover .know-more {
    opacity: ${(props) => (props.hover ? "1" : "0")};
  }
`;

const CardContent = styled.div`
  padding-left: ${theme.spacing(4)};
  padding-right: ${theme.spacing(4)};
  padding-top: ${theme.spacing(6)};
  padding-bottom: ${theme.spacing(6)};
`;

const CourseInfoStyle = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 14px;
  margin-top: 16px;
`;

const BadgePosition = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

interface HeaderCourseCardProps {
  spanCol: number;
  pathname: string;
  imgSrc: string;
  heading: string;
  description: string;
  nextCohortDate: string;
  programDuration: string;
  badge?: any;
}

const HeaderCourseCard = ({
  spanCol,
  pathname,
  imgSrc,
  badge,
  heading,
  description,
  nextCohortDate,
  programDuration,
}: HeaderCourseCardProps) => {
  return (
    <CardCont hover={true} spanCol={spanCol}>
      <Link className="link__" href={{ pathname: pathname }}>
        <Box height={140} overflow="hidden" className="imgbox">
          <ExportedImage
            width={320}
            height={140}
            src={imgSrc}
            sizes="100vw"
            style={{
              width: "100%",
              objectFit: "cover",
            }}
            alt=""
          />{" "}
          <BadgePosition>{badge}</BadgePosition>
        </Box>
        <CardContent>
          <Typography
            varient="heading6"
            color={"primary.900"}
            mb={theme.spacing(2)}
            weightVarient="semibold"
          >
            {heading}
          </Typography>
          <Typography varient="bodyxs" mb={theme.spacing(2)} weightVarient="medium">
            {description}
          </Typography>
          <CourseInfoStyle>
            <GradientLine />
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                gap: theme.spacing(1),
              }}
            >
              <div>
                <Typography
                  style={{
                    fontSize: "10px",
                    fontWeight: "400",
                    lineHeight: "16px",
                  }}
                  color={theme.colors.gray[700]}
                >
                  Next Cohort
                </Typography>{" "}
                <Typography
                  style={{
                    fontSize: "10px",
                    fontWeight: "600",
                    lineHeight: "16px",
                  }}
                  color={theme.colors.gray[700]}
                >
                  {nextCohortDate}
                </Typography>
              </div>
              <div>
                <Typography
                  style={{
                    fontSize: "10px",
                    fontWeight: "400",
                    lineHeight: "16px",
                  }}
                  color={theme.colors.gray[700]}
                >
                  Program Duration
                </Typography>
                <Typography
                  style={{
                    fontSize: "10px",
                    fontWeight: "600",
                    lineHeight: "16px",
                  }}
                  color={theme.colors.gray[700]}
                >
                  {" "}
                  {programDuration}
                </Typography>
              </div>
            </div>
          </CourseInfoStyle>
          <Typography
            varient="bodys"
            mt={theme.spacing(5)}
            className="know-more"
            color={"purple.800"}
            weightVarient="semibold"
          >
            Know more
          </Typography>
        </CardContent>
      </Link>
    </CardCont>
  );
};

export default HeaderCourseCard;
