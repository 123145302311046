import React, { memo } from "react";

import Box from "@/design-system/Box";
import Icon from "@/design-system/Icon";

import { theme as systemTheme } from "@/theme";

import Typography from "../Typography";

import { BadgeStyles, SizeStyles } from "./theme";
import { BadgePropTypes, ColorTypes } from "./types";

const Badge: React.FC<BadgePropTypes> = ({
  label = "Badge",
  leadingDot = false,
  leadingIcon,
  trailingIcon,
  size = "sm",
  theme = "dark",
  color = "primary",
  ...rest
}) => {
  const { bgColor, textColor, borderColor }: ColorTypes = BadgeStyles[theme][color];

  const { padding, fontStyles, iconSize, dotSize } = SizeStyles[size];

  return (
    <Box
      p={padding}
      display={"flex"}
      maxHeight={"28px"}
      alignItems={"center"}
      width={"fit-content"}
      height={"fit-content"}
      gap={systemTheme.spacing(1)}
      background={bgColor || "white"}
      borderRadius={systemTheme.radius(4)}
      border={borderColor ? `1px solid ${borderColor}` : "none"}
      {...rest}
    >
      {leadingDot && (
        <Box height={dotSize} width={dotSize} borderRadius={"50%"} background={textColor} />
      )}

      {leadingIcon && (
        <Icon
          varient={leadingIcon?.varient}
          type={leadingIcon?.type}
          size={iconSize}
          color={textColor}
        />
      )}

      <Typography
        color={textColor}
        style={{
          fontSize: fontStyles?.fontSize,
          lineHeight: fontStyles?.lineHeight,
          fontWeight: 600,
        }}
      >
        {label}
      </Typography>

      {trailingIcon && (
        <Icon
          varient={leadingIcon?.varient}
          type={leadingIcon?.type}
          size={iconSize}
          color={textColor}
        />
      )}
    </Box>
  );
};

export default memo(Badge);
